import {
    FormControl,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'

import { Form } from 'components/common'
import { useUser, useUserAPI } from 'hooks/user'
import { useConfig } from 'hooks/config'

const MessageSettingsForm = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { update } = useUserAPI()
    const { user, refetch } = useUser()
    const { data } = useConfig()

    const formik = useFormik({
        initialValues: {
            allowance: user?.messagingSettings?.allowance || 0,
        },
        onSubmit: values => {
            update({
                input: {
                    messagingSettings: values,
                },
            }).then(response => {
                if (response?.success) {
                    refetch()
                    enqueueSnackbar('Settings updated')
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    return (
        <Box
            sx={{
                padding: '20px',
            }}
        >
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="16px">
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="allowance">
                            Who can text
                        </InputLabel>
                        <RadioGroup
                            name="allowance"
                            value={formik.values.allowance}
                            onChange={formik.handleChange}
                        >
                            {data?.config?.messagingAllowance?.map(item => {
                                return (
                                    <FormControlLabel
                                        value={item?.key}
                                        control={<Radio />}
                                        label={item?.val}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                    <Button type="submit">Save</Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default MessageSettingsForm
