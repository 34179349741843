import React, { MouseEvent, useState } from 'react'

import {
    Button,
    MenuItem,
    Typography,
    Stack,
    Divider,
    IconButton,
    Box,
    Menu,
} from '@mui/material'
import { useMediaQuery } from '@mui/system'
import { MoreHoriz } from '@mui/icons-material'

import { Image, Tag } from 'components/common'
import { ChatMemberType } from 'api/generated'
import noImage from 'assets/images/event-no-image.jpg'
import { useUser } from '../../../hooks/user'

export interface ManageData {
    participants: Participant[]
}

interface ManageChatParticipantsProps {
    onSubmit: (data: ManageData) => void
    onCancel: () => void
    onNewChat: (id: string) => void
    onGoToChat: (companionId: string) => void
    members: ChatMemberType[]
    withPermissions: boolean
    usersWhoIHaveChatWith: string[]
}

interface Participant {
    id: string
    banned: boolean
    moderator: boolean
}

const ManageChatParticipants = ({
    onSubmit,
    onCancel,
    members,
    withPermissions = false,
    usersWhoIHaveChatWith,
    onNewChat,
    onGoToChat,
}: ManageChatParticipantsProps) => {
    const lessThan700px = useMediaQuery('(max-width:700px)')
    const { user } = useUser()

    const [anchorEl, setAnchorEl] = useState<null | {
        id: string
        element: HTMLElement
    }>(null)

    const [participants, setParticipants] = useState<Participant[]>(
        members?.map(m => ({
            id: m?.user?.id as string,
            banned: m?.isBanned as boolean,
            moderator: m?.role?.val === 'Moderator',
        }))
    )

    const handleClick = (id: string, event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl({
            id,
            element: event.currentTarget,
        })
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const renderCaption = (member: ChatMemberType) => {
        const isAdmin = member?.role?.val === 'Admin'
        const isModerator = participants.find(
            p => p.id === (member?.user?.id as string)
        )?.moderator
        const isBanned = participants.find(
            p => p.id === (member?.user?.id as string)
        )?.banned

        return (
            <Stack
                direction="row"
                gap="8px"
                alignItems="center"
                divider={
                    <Box
                        sx={{
                            width: '4px',
                            height: '4px',
                            backgroundColor: '#74747B',
                            borderRadius: '50%',
                        }}
                    />
                }
            >
                {isAdmin && (
                    <Typography
                        fontSize="15px"
                        fontWeight={500}
                        lineHeight="18px"
                        color="#008c44"
                        variant="caption"
                    >
                        Admin
                    </Typography>
                )}
                {isModerator && (
                    <Typography
                        fontSize="15px"
                        fontWeight={500}
                        lineHeight="18px"
                        color="primary"
                        variant="caption"
                    >
                        Moderator
                    </Typography>
                )}
                {withPermissions && isBanned && (
                    <Typography
                        fontSize="15px"
                        fontWeight={500}
                        lineHeight="18px"
                        color="error"
                        variant="caption"
                    >
                        Banned
                    </Typography>
                )}
                <Typography
                    fontSize="15px"
                    fontWeight={500}
                    lineHeight="18px"
                    color="#74747B"
                    noWrap
                    variant="caption"
                >
                    {member?.user?.school?.name}
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack
            divider={<Divider />}
            gap="12px"
            minWidth={lessThan700px ? '200px' : '600px'}
        >
            <Stack
                gap="12px"
                flexGrow={1}
                divider={<Divider />}
                sx={{
                    overflowY: 'auto',
                    maxHeight: '500px',
                }}
            >
                {members?.map(m => {
                    const isAdmin = m?.role?.val === 'Admin'
                    const isBanned = participants.find(
                        p => p.id === (m?.user?.id as string)
                    )?.banned
                    const isModerator = participants.find(
                        p => p.id === (m?.user?.id as string)
                    )?.moderator
                    const isMe = m?.user?.id === user?.id

                    return (
                        <Stack
                            key={m?.user?.id}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="35px"
                            sx={{
                                padding: '0 5px',
                            }}
                        >
                            <Stack
                                direction="row"
                                gap="12px"
                                alignItems="center"
                            >
                                <Image
                                    src={
                                        (m?.user?.avatar?.img48x48 as string) ||
                                        noImage
                                    }
                                    sx={{
                                        borderRadius: '50%',
                                        width: '48px',
                                        height: '48px',
                                    }}
                                />
                                <Stack
                                    gap="2px"
                                    sx={{
                                        maxWidth: '400px',
                                    }}
                                >
                                    <Stack direction="row" gap="8px">
                                        <Typography
                                            sx={{
                                                maxWidth: '120px',
                                            }}
                                            noWrap
                                            fontSize={
                                                lessThan700px ? '13px' : '16px'
                                            }
                                        >
                                            {`${m?.user?.firstName} ${m?.user?.lastName}`}
                                        </Typography>
                                        <Stack direction="row" gap="8px">
                                            {m?.user?.interests
                                                ?.filter((_, i) => i < 2)
                                                .map(i => (
                                                    <Tag key={i?.key}>
                                                        {i?.val as string}
                                                    </Tag>
                                                ))}
                                        </Stack>
                                    </Stack>
                                    {!lessThan700px &&
                                        renderCaption(m as ChatMemberType)}
                                </Stack>
                            </Stack>
                            <Stack
                                direction="row"
                                gap="12px"
                                alignItems="center"
                            >
                                {!withPermissions && !isMe && (
                                    <Button
                                        variant="text"
                                        sx={{
                                            padding: '11px 16px',
                                        }}
                                        onClick={() => {
                                            if (
                                                usersWhoIHaveChatWith.includes(
                                                    m?.user?.id as string
                                                )
                                            ) {
                                                onGoToChat(
                                                    m?.user?.id as string
                                                )
                                            } else {
                                                onNewChat(m?.user?.id as string)
                                            }

                                            onCancel()
                                        }}
                                    >
                                        Message
                                    </Button>
                                )}
                                {isMe && (
                                    <Typography
                                        fontSize={
                                            lessThan700px ? '13px' : '16px'
                                        }
                                        padding={
                                            lessThan700px
                                                ? withPermissions
                                                    ? '6px'
                                                    : '11px 6px'
                                                : withPermissions
                                                ? '11px'
                                                : '11px 16px'
                                        }
                                        color="#74747b"
                                    >
                                        You
                                    </Typography>
                                )}
                                {withPermissions && !isMe && (
                                    <>
                                        <IconButton
                                            onClick={e =>
                                                handleClick(
                                                    m?.user?.id as string,
                                                    e
                                                )
                                            }
                                            sx={{
                                                backgroundColor: '#f7f8fa',
                                                border: '1.25px solid #E4E4EB',
                                                borderRadius: '6px',
                                                padding: lessThan700px
                                                    ? '5px'
                                                    : '11px',
                                            }}
                                        >
                                            <MoreHoriz
                                                sx={{
                                                    fontSize: '20px',
                                                    color: '#1D1E20',
                                                }}
                                            />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl?.element}
                                            open={
                                                !!anchorEl &&
                                                anchorEl.id === m?.user?.id
                                            }
                                            disablePortal
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    if (
                                                        usersWhoIHaveChatWith.includes(
                                                            m?.user
                                                                ?.id as string
                                                        )
                                                    ) {
                                                        onGoToChat(
                                                            m?.user
                                                                ?.id as string
                                                        )
                                                    } else {
                                                        onNewChat(
                                                            m?.user
                                                                ?.id as string
                                                        )
                                                    }

                                                    onCancel()
                                                }}
                                            >
                                                Message
                                            </MenuItem>
                                            {!isAdmin && (
                                                <MenuItem
                                                    onClick={() => {
                                                        setParticipants(
                                                            prevState =>
                                                                prevState.map(
                                                                    p =>
                                                                        p.id ===
                                                                        m?.user
                                                                            ?.id
                                                                            ? {
                                                                                  ...p,
                                                                                  banned: !isBanned,
                                                                              }
                                                                            : p
                                                                )
                                                        )
                                                        handleClose()
                                                    }}
                                                >
                                                    {isBanned
                                                        ? 'Unban User'
                                                        : 'Ban User'}
                                                </MenuItem>
                                            )}
                                            {!isAdmin && (
                                                <MenuItem
                                                    onClick={() => {
                                                        setParticipants(
                                                            prevState =>
                                                                prevState.map(
                                                                    p =>
                                                                        p.id ===
                                                                        m?.user
                                                                            ?.id
                                                                            ? {
                                                                                  ...p,
                                                                                  moderator:
                                                                                      !isModerator,
                                                                              }
                                                                            : p
                                                                )
                                                        )

                                                        handleClose()
                                                    }}
                                                >
                                                    Set as{' '}
                                                    {isModerator
                                                        ? 'Participant'
                                                        : 'Moderator'}
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
            {withPermissions && (
                <Stack gap="12px" direction="row" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        sx={{
                            color: '#1D1E20',
                        }}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onSubmit({
                                participants,
                            })
                            onCancel()
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            )}
        </Stack>
    )
}

export default ManageChatParticipants
