import { useEffect, useState } from 'react'

import { useQuery, useSubscription } from '@apollo/client'

import {
    NotificationListDocument,
    NotificationListQueryVariables,
    NotificationType,
    OnNewNotificationDocument,
    ClubDetailsDocument,
    ActivityListDocument,
    EventDetailsDocument,
    MeDocument,
    UserListDocument,
    ClubListDocument,
    EventListDocument,
    ChatMessageListDocument,
    ChatListDocument,
    ChatDetailsDocument,
    ApplicationListDocument,
} from 'api/generated'

export const useNotifications = (
    variables: NotificationListQueryVariables = {}
) => {
    const [response, setResponse] = useState<NotificationType[]>([])

    const { data, loading, error, refetch, subscribeToMore } = useQuery(
        NotificationListDocument,
        {
            variables,
        }
    )

    useEffect(() => {
        if (data?.notificationList) {
            const notifications: NotificationType[] =
                data?.notificationList.edges.map(
                    edge => edge?.node as NotificationType
                )
            setResponse(notifications)
        }
    }, [data])

    return {
        notifications: response,
        loading,
        error,
        refetch,
        subscribeToMore,
    }
}

export function useNotificationEvent(room: string) {
    const { data } = useSubscription(OnNewNotificationDocument, {
        variables: { room },
        skip: !room,
        onData: options => {
            const { client, data } = options

            const payload = data.data?.onNewNotification

            if (payload?.notification?.type?.includes('chat_message')) {
                client.refetchQueries({
                    include: [
                        ChatMessageListDocument,
                        ChatListDocument,
                        ChatDetailsDocument,
                    ],
                })
            } else {
                client.refetchQueries({
                    include: [
                        ClubDetailsDocument,
                        EventDetailsDocument,
                        ActivityListDocument,
                        MeDocument,
                        UserListDocument,
                        ClubListDocument,
                        EventListDocument,
                        ChatDetailsDocument,
                        ChatListDocument,
                        ApplicationListDocument,
                    ],
                })
            }
        },
    })
    return { notification: data }
}
