import {
    FormControl,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Typography,
    Switch,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'

import { Form } from 'components/common'
import { useUser, useUserAPI } from 'hooks/user'
import { useConfig } from 'hooks/config'

const NotificationsPreferencesForm = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { update } = useUserAPI()
    const { user, refetch } = useUser()
    const { data } = useConfig()

    const formik = useFormik({
        initialValues: {
            group: user?.notificationSettings?.group,
            invitations: user?.notificationSettings?.invitations,
            applications: user?.notificationSettings?.applications,
        },
        onSubmit: values => {
            update({
                input: {
                    notificationSettings: values,
                },
            }).then(response => {
                if (response?.success) {
                    refetch()
                    enqueueSnackbar('Settings updated')
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    return (
        <Box
            sx={{
                padding: '20px',
            }}
        >
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="16px">
                    <FormControl>
                        <InputLabel shrink htmlFor="nonStudent">
                            Notify me about group updates
                        </InputLabel>
                        <Switch
                            value={formik.values.group}
                            defaultChecked={!!formik.values.group}
                            name="group"
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel shrink htmlFor="nonStudent">
                            Notify me about invitations
                        </InputLabel>
                        <Switch
                            value={formik.values.invitations}
                            defaultChecked={!!formik.values.invitations}
                            name="invitations"
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel shrink htmlFor="nonStudent">
                            Notify me about applications
                        </InputLabel>
                        <Switch
                            value={formik.values.applications}
                            defaultChecked={!!formik.values.applications}
                            name="applications"
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <Button type="submit">Save</Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default NotificationsPreferencesForm
