import React from 'react'

import { useFormik } from 'formik'
import {
    Button,
    Stack,
    Box,
    InputLabel,
    FormControl,
    InputBase,
    Switch,
    FormControlLabel,
    FormHelperText,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
    LoginFormValues,
    LoginFormInitialValues,
    LoginFormScheme,
} from './index.schema'
import { useAuth } from 'hooks/auth'
import { Form } from 'components/common'
import { PasswordField } from 'components/inputs'
import { authPaths } from 'routes'

interface LoginFormProps {
    onForgotPassword: () => void
}

const LoginForm = ({ onForgotPassword }: LoginFormProps): JSX.Element => {
    const { login, isLoading } = useAuth()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: LoginFormInitialValues,
        validationSchema: LoginFormScheme,
        validateOnChange: true,
        onSubmit: (values: LoginFormValues) => {
            login(values)
                .then(response => {
                    if (response?.errors?.[0]) {
                        formik.setErrors({
                            email: ' ',
                            password: 'Login or password are incorrect',
                        })
                    }
                })
                .catch(() => {
                    formik.setErrors({
                        email: ' ',
                        password: 'Login or password are incorrect',
                    })
                })
        },
    })

    const onForgotPasswordClicked = (): void => onForgotPassword()

    const isSubmitDisabled =
        (Object.keys(formik.touched).length > 1 &&
            Object.keys(formik.errors).length > 1) ||
        isLoading

    return (
        <Box>
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="24px">
                    <Stack gap="12px">
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="email">
                                Email
                            </InputLabel>
                            <InputBase
                                autoFocus
                                id="email"
                                fullWidth
                                name="email"
                                placeholder="e.g. example@email.com"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                            />
                            {formik.touched.email && formik.errors.email && (
                                <FormHelperText>
                                    {formik.errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="password">
                                Password
                            </InputLabel>
                            <PasswordField
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                name="password"
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                            />
                            {formik.touched.password &&
                                formik.errors.password && (
                                    <FormHelperText>
                                        {formik.errors.password}
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    value={formik.values.rememberMe}
                                    defaultChecked
                                    name="rememberMe"
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Remember me"
                        />
                        <Button
                            onClick={onForgotPasswordClicked}
                            variant="text"
                            sx={{
                                textTransform: 'capitalize',
                            }}
                        >
                            Forgot password?
                        </Button>
                    </Stack>
                    <Stack gap="8px">
                        <Button
                            sx={{
                                padding: '11px 16px',
                                fontSize: '15px',
                            }}
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={isSubmitDisabled}
                        >
                            Sign in
                        </Button>
                        <Button
                            onClick={() => navigate(`../${authPaths.signUp}`)}
                            sx={{
                                padding: '11px 16px',
                                fontSize: '15px',
                            }}
                            variant="text"
                            fullWidth
                        >
                            Create account
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Box>
    )
}

export default LoginForm
