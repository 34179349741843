import React from 'react'

import { Divider, Stack } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import { useNavigate } from 'react-router-dom'

import generateNavigationLinksByRole from './index.links'
import { RouterNavLink, Image } from 'components/common'
import logo from 'assets/images/logo.svg'
import notification from 'assets/images/notification.svg'
import { SearchField } from '../../inputs'
import { useUser } from 'hooks/user'
import { userPaths } from 'routes'
import { useDrawer } from 'hooks/common'
import { NotificationsLayout } from 'components/layout'
import { useNotifications } from '../../../hooks/notification'

const NavLinkStyles = {
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: '500',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#6B7280',
    '&:visited': {
        color: '#6B7280',
    },
}

const Header: React.FC = () => {
    const lessThan1100px = useMediaQuery('(max-width:1100px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')
    const lessThan550px = useMediaQuery('(max-width:550px)')

    const { user } = useUser()

    const navigate = useNavigate()
    const { open, close } = useDrawer()
    const { notifications } = useNotifications({
        ordering: '-created_at',
    })

    return (
        <Stack
            sx={{
                padding: lessThan550px ? '14px' : '14px 32px',
                background: (theme: Theme) => theme.colors.paperColor,
                display: 'flex',
                justifyContent: lessThan700px
                    ? lessThan550px
                        ? 'center'
                        : 'flex-end'
                    : 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                borderBottom: '1px solid #E5E7EB',
            }}
        >
            {!lessThan700px && (
                <Stack direction="row" gap="30px">
                    <Image src={logo} />
                    <SearchField compact={lessThan1100px} />
                </Stack>
            )}
            <Stack
                direction="row"
                divider={<Divider flexItem orientation="vertical" />}
                alignItems="center"
                gap={lessThan550px ? '18px' : '36px'}
            >
                <Stack gap={lessThan550px ? '14px' : '36px'} direction="row">
                    {generateNavigationLinksByRole(user?.role).map(link => {
                        return (
                            <RouterNavLink
                                end={link.end}
                                key={link.path}
                                to={link.path}
                                sx={{
                                    ...NavLinkStyles,
                                    '&.active': {
                                        color: '#000',
                                    },
                                }}
                            >
                                {link.title}
                            </RouterNavLink>
                        )
                    })}
                </Stack>
                <Stack
                    direction="row"
                    gap={lessThan550px ? '14px' : '25px'}
                    alignItems="center"
                >
                    <svg
                        onClick={() => navigate(`../${userPaths.chat}`)}
                        style={{
                            cursor: 'pointer',
                        }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="chat-alt-2">
                            <path
                                id="Vector"
                                d="M2.3999 5.99998C2.3999 5.36346 2.65276 4.75301 3.10285 4.30292C3.55293 3.85283 4.16338 3.59998 4.7999 3.59998H13.1999C13.8364 3.59998 14.4469 3.85283 14.897 4.30292C15.347 4.75301 15.5999 5.36346 15.5999 5.99998V10.8C15.5999 11.4365 15.347 12.0469 14.897 12.497C14.4469 12.9471 13.8364 13.2 13.1999 13.2H10.7999L7.1999 16.8V13.2H4.7999C4.16338 13.2 3.55293 12.9471 3.10285 12.497C2.65276 12.0469 2.3999 11.4365 2.3999 10.8V5.99998Z"
                                fill="#9398a2"
                            />
                            <path
                                id="Vector_2"
                                d="M17.9999 8.40002V10.8C17.9999 12.0731 17.4942 13.294 16.594 14.1941C15.6939 15.0943 14.473 15.6 13.1999 15.6H11.7935L9.67432 17.7204C10.0103 17.8992 10.3931 18 10.7999 18H13.1999L16.7999 21.6V18H19.1999C19.8364 18 20.4469 17.7472 20.897 17.2971C21.3471 16.847 21.5999 16.2365 21.5999 15.6V10.8C21.5999 10.1635 21.3471 9.55306 20.897 9.10297C20.4469 8.65288 19.8364 8.40002 19.1999 8.40002H17.9999Z"
                                fill="#9398a2"
                            />
                        </g>
                    </svg>
                    <Image
                        src={notification}
                        sx={{
                            cursor: 'pointer',
                            ...(lessThan550px && {
                                width: '18px',
                                height: '18px',
                            }),
                        }}
                        onClick={() => {
                            open({
                                component: NotificationsLayout,
                                options: {
                                    onClose: close,
                                    title: 'Notifications',
                                },
                                props: {
                                    onClose: close,
                                    items: notifications,
                                },
                            })
                        }}
                    />
                    <Image
                        src={user?.avatar?.img32x32 as string}
                        onClick={() => navigate(`../${userPaths.profile}`)}
                        sx={theme => ({
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            border: '1px solid transparent',
                            cursor: 'pointer',
                            '&:hover': {
                                borderColor: theme.colors.primary,
                            },
                        })}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}
export default Header
