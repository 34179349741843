import React from 'react'

import {
    Checkbox,
    Stack,
    TextField,
    Autocomplete,
    Typography,
    Box,
} from '@mui/material'
import { SxProps } from '@mui/system'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import { Image } from 'components/common'
import checkbox from 'assets/images/checkbox.svg'
import checkboxChecked from 'assets/images/checkbox-checked.svg'

export interface AutocompleteOption {
    label: string
    value: string | number
}

interface AutocompleteFieldProps {
    options: AutocompleteOption[]
    value: AutocompleteOption[]
    label?: string
    placeholder: string
    onChange: (value: string | number | string[] | number[]) => void
    state?: {
        touched?: boolean | boolean[]
        errors?: string | string[]
    }
    withSelectAll?: boolean
    sx?: SxProps<Theme>
    limitTags?: number
}

const MultipleAutocompleteField = ({
    options,
    value,
    state,
    onChange,
    label,
    placeholder = 'Select',
    withSelectAll = false,
    sx = {},
    limitTags = 1,
}: AutocompleteFieldProps) => {
    const allOptions = [
        ...(withSelectAll ? [{ label: 'Select All', value: 0 }] : []),
        ...options,
    ]

    return (
        <Autocomplete
            sx={{
                '& .MuiInputBase-root': {
                    borderRadius: '6px',
                    background: `#fff !important`,
                    '& input': {
                        width: 0,
                        '&::placeholder': {
                            color: '#1D1E20',
                            fontWeight: 500,
                        },
                    },
                },
                ...sx,
            }}
            openOnFocus
            disableClearable
            options={options.length === 0 ? [] : allOptions}
            limitTags={limitTags}
            multiple
            value={value.length === options.length ? allOptions : value}
            renderTags={values => {
                const limited = limitTags
                    ? values.filter(i => i.value !== 0).slice(0, limitTags)
                    : values.filter(i => i.value !== 0)

                const restCount = values.length - limited.length

                return (
                    <Stack
                        direction="row"
                        sx={{
                            marginRight: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: `${limitTags * 120}px`,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            <Typography
                                variant="caption"
                                fontSize="14px"
                                fontWeight={500}
                                color="#1D1E20"
                            >
                                {limited.map(i => i.label).join(', ')}
                            </Typography>
                        </Box>

                        {restCount > 0 ? (
                            <Typography
                                variant="caption"
                                color="#1C64F2"
                                fontSize="14px"
                                fontWeight={500}
                            >{`, +${restCount}`}</Typography>
                        ) : (
                            ''
                        )}
                    </Stack>
                )
            }}
            disableCloseOnSelect
            onChange={(e, value, reason, details) => {
                if (details?.option.value === 0) {
                    if (reason === 'removeOption') {
                        onChange([])
                    } else if (reason === 'selectOption') {
                        onChange(options.map(i => i?.value as number))
                    }
                } else {
                    onChange(
                        value
                            .filter(i => i.value !== 0)
                            .map(v => v?.value as number)
                    )
                }
            }}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            renderOption={(props, option, { selected }) => {
                const extraProps = {
                    ...(option.value === 0 && {
                        'data-select-all': true,
                    }),
                }

                return (
                    <li {...props} {...extraProps} key={option.value}>
                        <Checkbox
                            icon={<Image src={checkbox} />}
                            checkedIcon={<Image src={checkboxChecked} />}
                            checked={
                                option.value === 0
                                    ? value?.length === options.length
                                    : selected
                            }
                        />
                        {option.label}
                    </li>
                )
            }}
            fullWidth
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={value.length > 0 ? '' : placeholder}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={state && state.touched && Boolean(state.errors)}
                        helperText={state && state.touched && state.errors}
                    />
                )
            }}
            popupIcon={<KeyboardArrowDown />}
        />
    )
}

export default MultipleAutocompleteField
