import {
    FormControl,
    InputLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'

import { useUser, useUserAPI } from 'hooks/user'
import { Form } from 'components/common'

const VisibilityUpdateForm = () => {
    const { update } = useUserAPI()
    const { user, refetch } = useUser()

    const formik = useFormik({
        initialValues: {
            showAvatar: !!user?.visibilitySettings?.showAvatar,
            showPhotos: !!user?.visibilitySettings?.showPhotos,
            showGrade: !!user?.visibilitySettings?.showGrade,
            showClubs: !!user?.visibilitySettings?.showClubs,
            showEvents: !!user?.visibilitySettings?.showEvents,
            showClubMembership: !!user?.visibilitySettings?.showClubMembership,
            showEventMembership:
                !!user?.visibilitySettings?.showEventMembership,
            showFoodPreferences:
                !!user?.visibilitySettings?.showFoodPreferences,
            showBio: !!user?.visibilitySettings?.showBio,
        },
        onSubmit: values => {
            const newValues: Record<string, boolean> = {}
            Object.entries(values).forEach(([key, value]) => {
                if (value?.toString() === 'true') {
                    newValues[key] = true
                } else {
                    newValues[key] = false
                }
            })
            update({
                input: {
                    visibilitySettings: newValues,
                },
            }).then(response => {
                if (response?.success) {
                    refetch()
                    enqueueSnackbar('Settings updated')
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    return (
        <Box
            sx={{
                padding: '20px',
            }}
        >
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="16px">
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showAvatar">
                            Profile picture
                        </InputLabel>
                        <RadioGroup
                            name="showAvatar"
                            value={formik.values.showAvatar}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showPhotos">
                            Photos
                        </InputLabel>
                        <RadioGroup
                            name="showPhotos"
                            value={formik.values.showPhotos}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    {!user?.isNonStudent && (
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="email">
                                Grade
                            </InputLabel>
                            <RadioGroup
                                name="showGrade"
                                value={formik.values.showGrade}
                                onChange={formik.handleChange}
                            >
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="Private"
                                />

                                <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="Public"
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showClubs">
                            Clubs created by user
                        </InputLabel>
                        <RadioGroup
                            name="showClubs"
                            value={formik.values.showClubs}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showClubMembership">
                            Clubs where the user is a member
                        </InputLabel>
                        <RadioGroup
                            name="showClubMembership"
                            value={formik.values.showClubMembership}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showEvents">
                            Events created by user
                        </InputLabel>
                        <RadioGroup
                            name="showEvents"
                            value={formik.values.showEvents}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showEventMembership">
                            Events where user is a member
                        </InputLabel>
                        <RadioGroup
                            name="showEventMembership"
                            value={formik.values.showEventMembership}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showFoodPreferences">
                            Food preferences
                        </InputLabel>
                        <RadioGroup
                            name="showFoodPreferences"
                            value={formik.values.showFoodPreferences}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="showBio">
                            Show BIO
                        </InputLabel>
                        <RadioGroup
                            name="showBio"
                            value={formik.values.showBio}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Private"
                            />

                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Public"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Button type="submit">Save</Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default VisibilityUpdateForm
