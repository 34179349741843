import React, { useEffect, useRef, useState } from 'react'

import {
    FormControl,
    InputLabel,
    Stack,
    Button,
    FormHelperText,
    InputBase,
    Switch,
    Typography,
} from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'

import { Dropzone, Form, Image } from 'components/common'
import { MultipleAutocompleteField } from 'components/inputs'
import { useConfig } from 'hooks/config'
import { useUserAPI, useUser } from 'hooks/user'
import { UserUpdateInput } from 'api/generated'
import { ProfileUpdateValidationSchema } from './index.schema'

const ProfileUpdateForm = () => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const dropzoneRef = useRef(null)
    const { update } = useUserAPI()
    const { user, refetch } = useUser()

    const { data } = useConfig()
    const [file, setFile] = useState<File | null>(null)
    const [preview, setPreview] = useState<string | null | undefined>(
        user?.avatar?.img100x100
    )
    const [isPhotoDeleted, setPhotoDeleted] = useState(false)

    useEffect(() => {
        setPreview(user?.avatar?.img100x100)
    }, [user?.avatar?.img100x100])

    const formik = useFormik({
        initialValues: {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            bio: user?.bio || '',
            grade: user?.grade?.key || 0,
            school: user?.school?.id,
            nonStudent: user?.isNonStudent || false,
            interests: user?.interests?.map(item => item?.key) || [],
            foodPreferences:
                user?.foodPreferences?.map(item => item?.key) || [],
        },
        validationSchema: ProfileUpdateValidationSchema,
        onSubmit: values => {
            const { interests, foodPreferences, nonStudent, grade, ...rest } =
                values

            const input: UserUpdateInput = {
                ...rest,
                ...((file || isPhotoDeleted) && {
                    avatar: file,
                }),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                interests,
                nonStudent,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                foodPreferences,
            }

            if (!nonStudent) {
                input.grade = grade
            }
            update({
                input,
            }).then(response => {
                if (response?.success) {
                    refetch()
                    enqueueSnackbar('Settings updated')
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    const onFileSelect = () => {
        if (dropzoneRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dropzoneRef.current.open()
        }
    }

    const onFileRemove = () => {
        setFile(null)
        setPreview(null)
        setPhotoDeleted(true)
    }

    const interestOptions =
        data?.config?.interests?.map(s => ({
            value: s?.key as number,
            label: s?.val as string,
        })) || []

    const foodOptions =
        data?.config?.food?.map(s => ({
            value: s?.key as number,
            label: s?.val as string,
        })) || []

    return (
        <Box
            sx={{
                padding: '20px',
            }}
        >
            <Form
                sx={{ width: lessThan700px ? '100%' : '320px' }}
                onSubmit={formik.handleSubmit}
            >
                <Stack gap="16px">
                    <Stack direction="row" gap="24px">
                        <Stack
                            position="relative"
                            sx={{
                                width: '140px',
                                height: '140px',

                                borderRadius: '50%',
                                '.MuiBox-root': {
                                    borderRadius: '50%',
                                },
                            }}
                        >
                            <Dropzone
                                ref={dropzoneRef}
                                PreviewProps={{
                                    sx: {
                                        borderRadius: '50%',
                                        width: '140px',
                                        height: '140px',
                                    },
                                }}
                                onSelect={files => {
                                    const preview = URL.createObjectURL(
                                        files[0]
                                    )
                                    setFile(files[0])
                                    setPreview(preview)
                                    setPhotoDeleted(false)
                                }}
                                onReject={() => {}}
                                multiple={false}
                                preview={preview}
                            />

                            {preview && (
                                <Image
                                    width={140}
                                    height={140}
                                    sx={{
                                        position: 'absolute',
                                        top: '0',
                                        borderRadius: '50%',
                                    }}
                                    src={preview}
                                />
                            )}
                        </Stack>
                        <Stack gap="16px">
                            <Button
                                sx={{
                                    border: '1px solid #E4E4EB',
                                    background: '#F7F8FA',
                                    color: '#1D1E20',
                                    '&:hover': {
                                        background: '#E4E4EB',
                                    },
                                }}
                                onClick={onFileSelect}
                            >
                                Upload photo
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    border: '1px solid #C81E1E',
                                    background: 'transparent',
                                    color: '#1D1E20',
                                    '&:hover': {
                                        border: '1px solid #C81E1E',
                                    },
                                }}
                                onClick={onFileRemove}
                            >
                                Delete photo
                            </Button>
                        </Stack>
                    </Stack>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="firstName">
                            First name{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <InputBase
                            id="firstName"
                            fullWidth
                            name="firstName"
                            placeholder="First name"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                            }
                        />
                        {formik.touched.firstName &&
                            formik.errors.firstName && (
                                <FormHelperText>
                                    {formik.errors.firstName}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="lastName">
                            Last name{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <InputBase
                            id="lastName"
                            fullWidth
                            name="lastName"
                            placeholder="First name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.lastName)
                            }
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                            <FormHelperText>
                                {formik.errors.lastName}
                            </FormHelperText>
                        )}
                    </FormControl>
                    {!formik.values.nonStudent && (
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="grade">
                                Grade{' '}
                                <Typography variant="caption" color="#CE321E">
                                    *
                                </Typography>
                            </InputLabel>
                            <InputBase
                                id="grade"
                                fullWidth
                                name="grade"
                                type="number"
                                placeholder="Grade"
                                value={formik.values.grade}
                                onChange={formik.handleChange}
                                inputProps={{
                                    min: 1,
                                    max: 5,
                                }}
                                error={
                                    formik.touched.grade &&
                                    Boolean(formik.errors.grade)
                                }
                            />
                            {formik.touched.grade && formik.errors.grade && (
                                <FormHelperText>
                                    {formik.errors.grade}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                    <FormControl>
                        <InputLabel shrink htmlFor="nonStudent">
                            Non-student
                        </InputLabel>
                        <Switch
                            value={formik.values.nonStudent}
                            defaultChecked={formik.values.nonStudent}
                            name="nonStudent"
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <MultipleAutocompleteField
                        limitTags={3}
                        withSelectAll
                        value={
                            foodOptions?.filter(i => {
                                return (
                                    formik.values.foodPreferences as number[]
                                )?.includes(i?.value as number)
                            }) || []
                        }
                        options={foodOptions || []}
                        label="Food preferences"
                        placeholder="Select"
                        state={{
                            errors: formik.errors.foodPreferences,
                            touched: formik.touched.foodPreferences,
                        }}
                        onChange={value =>
                            formik.setFieldValue('foodPreferences', value)
                        }
                    />
                    <MultipleAutocompleteField
                        limitTags={3}
                        withSelectAll
                        value={
                            interestOptions?.filter(i => {
                                return (
                                    formik.values.interests as number[]
                                )?.includes(i?.value as number)
                            }) || []
                        }
                        options={interestOptions || []}
                        label="Interests"
                        placeholder="Select"
                        state={{
                            errors: formik.errors.interests,
                            touched: formik.touched.interests,
                        }}
                        onChange={value =>
                            formik.setFieldValue('interests', value)
                        }
                    />

                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="bio">
                            Bio
                        </InputLabel>
                        <InputBase
                            multiline
                            minRows={6}
                            maxRows={10}
                            id="bio"
                            fullWidth
                            name="bio"
                            placeholder="Bio"
                            value={formik.values.bio}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.bio && Boolean(formik.errors.bio)
                            }
                        />
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={
                                formik.touched.bio && formik.errors.bio
                                    ? 'space-between'
                                    : 'flex-end'
                            }
                        >
                            {formik.touched.bio && formik.errors.bio && (
                                <Typography
                                    textAlign="right"
                                    fontSize="12px"
                                    lineHeight="15px"
                                    color={
                                        formik.touched.bio && formik.errors.bio
                                            ? 'error'
                                            : '#000'
                                    }
                                >
                                    {formik.errors.bio}
                                </Typography>
                            )}
                            <Typography
                                textAlign="right"
                                fontSize="12px"
                                lineHeight="15px"
                                color={
                                    formik.touched.bio && formik.errors.bio
                                        ? 'error'
                                        : '#000'
                                }
                            >
                                {formik.values.bio.length}/255
                            </Typography>
                        </Stack>
                    </FormControl>
                    <Button type="submit">Save</Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default ProfileUpdateForm
