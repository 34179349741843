import React, { useEffect } from 'react'

import { useSnackbar } from 'notistack'
import { useMatch } from 'react-router-dom'

import { useNotificationEvent } from 'hooks/notification'
import { useUser } from 'hooks/user'

const NotificationWidget = () => {
    const isChatPage = useMatch('/chat')
    const { enqueueSnackbar } = useSnackbar()
    const { user } = useUser()
    const { notification } = useNotificationEvent(user?.id as string)

    useEffect(() => {
        if (notification?.onNewNotification) {
            const payload = notification?.onNewNotification.notification
            const type = payload?.type

            if (isChatPage) {
                if (!type?.includes('chat_message')) {
                    enqueueSnackbar({
                        key: 'notification',
                        variant: 'notification',
                        autoHideDuration: 15000,
                        data: payload,
                    })
                }
            } else {
                console.log(payload)
                if (
                    type !== 'chat_message_update' &&
                    type !== 'chat_message_delete'
                ) {
                    enqueueSnackbar({
                        key: 'notification',
                        variant: 'notification',
                        autoHideDuration: 15000,
                        data: payload,
                    })
                }
            }
        }
    }, [notification])

    return null
}

export default NotificationWidget
