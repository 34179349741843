import { styled } from '@mui/material/styles'
import { Link, NavLink } from 'react-router-dom'

const RouterLink = styled(Link)``
const Image = styled('img')``
const Span = styled('span')``
const Form = styled('form')``
const B = styled('b')``
const RouterNavLink = styled(NavLink)``

export { RouterLink, RouterNavLink, B, Span, Form, Image }
