import React from 'react'

import { IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

interface NotificationBarProps {
    text: string
}

const NotificationBar = ({ text }: NotificationBarProps) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                backgroundColor: '#F4F8FF',
                border: '1px solid #E4E4EB',
                padding: '15px 24px',
                borderRadius: '7px',
                marginBottom: '36px',
            }}
        >
            <Typography fontSize="15px" lineHeight="18px" color="#1D1E20">
                {text}
            </Typography>
            <IconButton
                sx={{
                    padding: 0,

                    '& .MuiSvgIcon-root': {
                        width: '18px',
                        height: '18px',
                    },
                }}
            >
                <Close />
            </IconButton>
        </Stack>
    )
}

export default NotificationBar
