import React, { useEffect, useState } from 'react'

import { InputAdornment, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'

import { Image } from 'components/common'
import search from 'assets/images/search.svg'
import { useDebounced } from 'hooks/common'

interface SearchFieldProps {
    compact?: boolean
    placeholder?: string
    onChange?: (text: string) => void
    customWidth?: string
}

const SearchField = ({
    compact = false,
    onChange = () => {},
    placeholder = 'Search: Users, Clubs, Events',
    customWidth,
}: SearchFieldProps) => {
    const [searchText, setSearchText] = useState('')

    const debouncedSearchText = useDebounced(searchText, 500)

    useEffect(() => {
        onChange(debouncedSearchText)
    }, [debouncedSearchText])

    return (
        <TextField
            placeholder={placeholder}
            // type="search"
            sx={{
                width: compact ? '48px' : customWidth || '328px',
            }}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            InputProps={{
                sx: {
                    background: 'white',
                    borderRadius: '8px',
                    '& .MuiInputBase-input': {
                        paddingLeft: '2px',
                        background: 'white',
                        borderColor: 'transparent',
                        '&:focus': {
                            boxShadow: `none`,
                            borderColor: 'transparent',
                        },
                    },
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <Image src={search} />
                    </InputAdornment>
                ),
                ...(searchText.length > 0 && {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={() => setSearchText('')}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <Close
                                sx={{
                                    fontSize: '20px',
                                }}
                            />
                        </InputAdornment>
                    ),
                }),
            }}
        />
    )
}

export default SearchField
