import {
    FormControl,
    InputLabel,
    InputBase,
    FormHelperText,
    Typography,
    Button,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'

import { PasswordField } from 'components/inputs'
import { Form } from 'components/common'
import { useAuth } from 'hooks/auth'
import { SecurityUpdateFormScheme } from './index.schema'

const SecurityUpdateForm = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { changePassword } = useAuth()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            newPassword: '',
            newPassword2: '',
        },
        validationSchema: SecurityUpdateFormScheme,
        onSubmit: values => {
            changePassword({
                currentPassword: values.password,
                newPassword: values.newPassword,
                repeatPassword: values.newPassword2,
            })
                .then(response => {
                    if (response?.success) {
                        enqueueSnackbar('Password changed')
                    } else {
                        enqueueSnackbar('Error happened')
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        },
    })

    return (
        <Box
            sx={{
                padding: '20px',
                width: '320px',
            }}
        >
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="16px">
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="firstName">
                            Password{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <PasswordField
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            name="password"
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                        />
                        {formik.touched.password && formik.errors.password && (
                            <FormHelperText>
                                {formik.errors.password}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="newPassword">
                            New password{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <PasswordField
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            name="newPassword"
                            error={
                                formik.touched.newPassword &&
                                Boolean(formik.errors.newPassword)
                            }
                        />
                        {formik.touched.newPassword &&
                            formik.errors.newPassword && (
                                <FormHelperText>
                                    {formik.errors.newPassword}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink htmlFor="newPassword2">
                            Repeat new password{' '}
                            <Typography variant="caption" color="#CE321E">
                                *
                            </Typography>
                        </InputLabel>
                        <PasswordField
                            value={formik.values.newPassword2}
                            onChange={formik.handleChange}
                            name="newPassword2"
                            error={
                                formik.touched.newPassword2 &&
                                Boolean(formik.errors.newPassword2)
                            }
                        />
                        {formik.touched.newPassword2 &&
                            formik.errors.newPassword2 && (
                                <FormHelperText>
                                    {formik.errors.newPassword2}
                                </FormHelperText>
                            )}
                    </FormControl>
                    <Button type="submit">Save</Button>
                </Stack>
            </Form>
        </Box>
    )
}

export default SecurityUpdateForm
